import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import MultiModelSearch from '../components/multiModelSearch.vue'
import CreateProducts from "@/components/createProducts.vue";
import AllProducts from "@/components/allProducts.vue";
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        children: [
            {
                path: 'multimodelsearch',
                component: MultiModelSearch,
            },
            {
                path: 'createproducts',
                component: CreateProducts,
            },
            {
                path: 'products',
                component: AllProducts,
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
]

const router = new VueRouter({
    // mode: 'history',
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const token = VueCookies.get('access_token'); // 使用 Vue.$cookies.get()
    // console.log(token);
    if (to.name !== 'login' && !token) {
        alert('请先登录');
        next({
            path: '/login',
            query: {redirect: to.fullPath}
        });
    } else {
        next();
    }
});
export default router
