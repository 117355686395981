export const ProductTypes = [
    '背心', '开衫', '帽衫', '围巾', '连衣裙',
    '拉链背心', '拉链外套', '加绒拉链外套',
    '帽衫长袖', '帽衫针织长袖', '帽衫拉链外套',
    '假两件毛衣', '假两件开衫',
    'T领长袖',
    '套头长袖', '套头帽衫长袖', '套头假两件长袖',
    '针织长袖', '针织短袖', '针织长袖半拉链', '针织长袖-T领',
];

export const NeedleTypes = [
    '圆机', '3G', '5G', '6.2G', '7G', '一体机7.2G', '9G', '一体机10.2G', '12G', '14G', '一体机14.2G', '16G', '一体机16.2G',
    '18G',
];

export const Week_Month_ThreeMonths = {
    // 日期选择器的配置1
    shortcuts: [{
        text: '最近一周',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近一个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
        }
    }, {
        text: '最近三个月',
        onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
        }
    }]
};

export const Yesterday_Today_Week = {
    shortcuts: [{
        text: '今天',
        onClick(picker) {
            picker.$emit('pick', new Date());
        }
    }, {
        text: '昨天',
        onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
        }
    }, {
        text: '一周前',
        onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
        }
    }]
};

export const HelpText =  `
        <h1>介绍</h1>  
        <ul>  
            <li><h3>主体分为<span style="color:red;">知识库 样品库</span></h3></li>  
            <li><h3><span style="color:red;">样品库</span>包含所有样衣信息，<span style="color:red;">知识库</span>包含可被<img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240924092506138.png" alt="图片" />搜索到的数据</h3></li>  
         </ul>  
        <h1>添加样品</h1>  
        <ul>  
            <li><h3>根据提示信息填写相关信息，内容都可以为空，但是<span style="color:red;">款号必须填写</span></h3></li>  
            <li><h3>创建完的样品数据默认会存储到样品库中，还需手动添加至知识库，如果<img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913134016814.png" />状态开启那么会自动在创建样品的时候将其上传到知识库</h3></li>
            <li><h3><img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913133738442.png" />Excel批量导入的表头必须包含以下内容
            <img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913133843214.png" />且文件后缀必须为.xlsx</h3></li>
         </ul>  
        <h1>查询知识库</h1>  
        <ul>  
            <li><h3><img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913134135896.png" />知识库中的数据可以用多模态方式和关键字方式进行查询</h3></li>  
            <li><h3>关键字查询只会返回包含关键字的知识库数据</h3></li>
            <li><h3>多模态查询可以通过"描述"进行模糊性查询，例如："藏青色的男装外套，有拉链和口袋，胸口有绿色的胸章"，同时还可以通过其它查询条件对结果进行约束</h3></li>
         </ul>  
        <h1>样品库</h1>  
        <ul>  
            <li><h3>样品库中的数据分为<img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913111344715.png" alt="图片" />两种状态
            红色表示未被知识库收录，绿色表示已被知识库收录，只有绿色的样品才能使用知识库查询，点击<img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913132552269.png" />
             可以将数据添加至知识库</h3></li>
            <li><h3>编辑过的样品会自动从知识库下架，需要手动的重新上传至知识库</h3></li>
            <li><h3><img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913132818932.png" />将会把样品库和知识库中的数据同时删除，批量删除同理</h3></li>
            <li><h3><img src="https://91cppimage.oss-cn-hangzhou.aliyuncs.com/imgstudy/image-20240913132935274.png" />自动上传会将所有样品库中的数据上传至知识库，过程可能比较缓慢，清空知识库则是仅删除所有知识库数据，样品库数据不受影响</h3></li>
            

            
         </ul>  
            
    `;

export const OrderDict = {'知识库状态':'is_vectorized','克重':'gram_weight','男/女款':'gender_type',
    '织机时间(分)':'knitting_time', '生产日期':'manufactured_at',}