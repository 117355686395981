import {AxiosClient, config as $config} from "@/conf/index.js";

export async function ChangePasswordAPI(formData) {
    const res = { error: false, data: null, message: '' };
    await AxiosClient.getInstance().put(`${$config.CHANGE_PASSWORD_URL}`, formData)
        .then(response => {
            if (response.status === 200) {
                res.data = response.data;
                res.message = "密码修改成功";
            }
        }).catch(error => {
            console.log(error.response.data.message);
            res.error = true;
            res.message = error.response.data.message.replace(/^\[|]$/g, '');
        });
    return res;
}
