import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
// import axios from "axios"
// import $config from "@/conf"
import VueCookies from 'vue-cookies'
import store from '@/store/user';

Vue.use(VueCookies)
Vue.use(ElementUI)
Vue.config.productionTip = false
// axios.defaults.baseURL = $config.BASEURL


new Vue({
    router,
    store,
    el: '#app',
    render: h => h(App),
    created() {
        // 在这里可以直接使用 Vue.$cookies
        this.$cookies = Vue.$cookies; // 这样可以在组件中通过 this.$cookies 访问
    }
}).$mount('#app')
