<script>
import {DeleteVectorAPI} from "@/util/VectorAPI";

import {NeedleTypes, ProductTypes, Week_Month_ThreeMonths} from "@/assets/Constants";
import {MultimodelSearchAPI} from "@/util/MultimodelSearchAPI";

export default {
  name: 'MultiModelSearch',
  data() {
    return {
      srcList: [],// 展示图片的数组
      imgPreview: null,// 展示图片的预览
      loading: false,// 加载状态
      img_file: null,// 上传图片的对象
      GenderTypes: ['男装', '女装'],
      ProductTypes: ProductTypes,
      NeedleTypes: NeedleTypes,
      pickerOptions: Week_Month_ThreeMonths,

      submitData: {
        // 表单提交数据
        search_type: 'nerual',
        product_id: '',
        product_type: '',
        needle_type: '',
      },
      form: {
        // 表单数据
        gender_type: null,
        temp_search_type: true,
        product_id: null,
        product_type: null,
        needle_type: null,
        min_gram_weight: '',
        max_gram_weight: '',
        composition: null,
        pattern: null,
        knitting_start_date: null,
        knitting_end_date: '',
        manufactured_date: '',
        description: '',
        product_image: null,
        size: 5,
      },
      dialogVisible: false,// 展示详情弹窗 默认关闭
      selectedProduct: {},// 展示详情弹窗中被选中的对象
      responseData: null,// 服务器响应的原始数据
      transformedList: [],// 展示表格中被转换后的数组
      multipleSelection: [],// 多选框选中的值
    }
  },
  methods: {
    handleChange(event) {
      console.log(event.target.files[0]);
      this.img_file = event.target.files[0];
      if (this.img_file) {
        this.imgPreview = URL.createObjectURL(this.img_file);
      }
    },
    transformGarmentList(garmentList) {
      // 创建一个新数组来存放转换后的对象
      const transformedList = [];

      // 遍历原始数组
      garmentList.forEach(item => {
        // Object.keys(item) 获取当前对象的所有键，因为知道每个对象只有一个键，所以直接取第一个
        const key = Object.keys(item)[0];
        // 将对应的值（即我们需要的对象）添加到新数组中
        transformedList.push(item[key]);
      });

      return transformedList;
    },
    removeEmptyValue(obj) {
      for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "" || Number.isNaN(obj[key])) {
          delete obj[key];
        }
      }
      return obj;
    },
    resetForm() {
      location.reload();
    },
    async onSubmit() {
      console.log(this.form.manufactured_date);
      if (!this.form.temp_search_type) {
        this.submitData.search_type = 'keyword';
      } else {
        this.submitData.search_type = 'nerual';
      }
      this.submitData.product_id = this.form.product_id;
      this.submitData.gender_type = this.form.gender_type;
      this.submitData.product_type = this.form.product_type;
      this.submitData.needle_type = this.form.needle_type;
      this.submitData.gram_weight = this.form.gram_weight;
      this.submitData.composition = this.form.composition;
      this.submitData.pattern = this.form.pattern;
      this.submitData.technologist = this.form.technologist;
      this.submitData.min_gram_weight = this.form.min_gram_weight;
      this.submitData.max_gram_weight = this.form.max_gram_weight;
      this.submitData.knitting_start_date = this.form.knitting_start_date;
      this.submitData.knitting_end_date = this.form.knitting_end_date;
      this.submitData.manufactured_start_date = this.form.manufactured_date[0];
      this.submitData.manufactured_end_date = this.form.manufactured_date[1];
      this.submitData.description = this.form.description;
      this.submitData.size = this.form.size;
      this.submitData.product_image = this.form.product_image;
      this.submitData = this.removeEmptyValue(this.submitData);
      console.log(this.submitData);
      const formData = new FormData();
      for (const [key, value] of Object.entries(this.submitData)) {
        formData.append(key, value);
      }
      if (this.img_file) {
        formData.append('product_image', this.img_file);
      }
      this.loading = true;

      const multiRes = await MultimodelSearchAPI(formData)
      if (multiRes.status === 200) {
        console.log(multiRes.data);
        this.responseData = multiRes.data.data;
        this.transformedList = this.transformGarmentList(this.responseData.garment_list);
        this.srcList = this.transformedList.flatMap(item =>
            (item.product_images || []).map(image => image.name)
        );
        this.loading = false;
        console.log(this.transformedList)
      } else {
        this.$message.error('查询失败');
      }
      console.log(this.srcList);
    },
    showDetails(row) {
      this.selectedProduct = row;
      this.dialogVisible = true;
    },
    removeVector(row, index) {
      console.log(index);
      console.log(this.transformedList);
      const formData = new FormData();
      formData.append('pk', [row.id]);
      this.$confirm('确认移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const res = DeleteVectorAPI([row.id])
        res.then(res => {
          console.log(res.data);
          this.$message({
            type: 'success',
            message: '移除成功!'
          })
          this.transformedList.splice(index, 1)
        }).catch(error => {
          console.log(error);
          this.$message({
            type: 'error',
            message: '移除失败!'
          })
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消移除'
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(`selected: ${val.length} items`, val);
    },
    handleBatchDeleteVector() {
      const idList = []
      for (let i = 0; i < this.multipleSelection.length; i++) {
        // console.log(this.multipleSelection[i].id);
        idList.push(this.multipleSelection[i].id);
      }
      console.log(idList);
      this.$confirm('确认批量移除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < idList.length; i++) {
          DeleteVectorAPI([idList[i]])
        }
      this.$message({
        type: 'success',
        message: '批量移除成功!'
      })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消批量移除'
        });
      });

    },
  }
}
</script>

<template>
  <div class="search-container">
    <!--    查询表单-->
    <el-form ref="form" :model="form" label-width="70px" label-position="right">
      <el-form-item>
        <el-switch
            class="custom-switch-left-margin"
            v-model="form.temp_search_type"
            active-text="多模态查询"
            inactive-text="关键字查询">
        </el-switch>
      </el-form-item>
      <el-form-item label="款号">
        <el-input v-model="form.product_id"></el-input>
      </el-form-item>
      <el-form-item label="产品类型">
        <el-select v-model="form.product_type" placeholder="选择产品类型">
          <el-option
              v-for="item in ProductTypes"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="男/女款">
        <el-select v-model="form.gender_type" placeholder="选择款式类型">
          <el-option
              v-for="item in GenderTypes"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="针型">
        <el-select v-model="form.needle_type" placeholder="选择针型">
          <el-option
              v-for="item in NeedleTypes"
              :key="item"
              :label="item"
              :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="克重(克)">
        <el-col :span="11">
          <el-input-number v-model="form.min_gram_weight" :precision="2" :step="0.1" :max="10000"></el-input-number>
        </el-col>
        <el-col :span="2">-</el-col>
        <el-col :span="11">
          <el-input-number v-model="form.max_gram_weight" :precision="2" :step="0.1" :max="10000"></el-input-number>
        </el-col>
      </el-form-item>
      <el-form-item label="材质">
        <el-input v-model="form.composition"></el-input>
      </el-form-item>
      <el-form-item class="product-item" label="工艺师">
        <el-input v-model="form.technologist" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item label="针法">
        <el-input v-model="form.pattern"></el-input>
      </el-form-item>
      <el-form-item label="织机时间(分钟)">
        <el-col :span="11">
          <el-input-number v-model="form.knitting_start_date" :precision="2" :step="0.1" :max="10000"></el-input-number>
        </el-col>
        <el-col :span="2">-</el-col>
        <el-col :span="11">
          <el-input-number v-model="form.knitting_end_date" :precision="2" :step="0.1" :max="10000"></el-input-number>
        </el-col>
      </el-form-item>
      <el-form-item label="生产日期">
        <el-date-picker
            v-model="form.manufactured_date"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" v-model="form.description"></el-input>
      </el-form-item>
      <el-form-item label="上传图片">
        <input type="file" @change="handleChange">
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        <div v-if="imgPreview">
          <el-image :src="imgPreview" class="img-preview" alt="图片预览" style="max-width: 200px; margin-top: 10px;"/>
        </div>
      </el-form-item>


      <el-form-item label="返回数量">
        <el-input-number v-model="form.size" :min="5" :max="100"></el-input-number>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="onSubmit" style="margin-top: 15px;">查询</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>
    </el-form>

    <!--    展示框-->
    <div v-if="responseData" class="search-result">
      <el-button
          v-if="multipleSelection.length > 0"
          type="danger" @click="handleBatchDeleteVector()" class="batch-delete-btn"
          size="small">
        批量移除
      </el-button>
      <el-button
          v-else
          @click="handleBatchDeleteVector()"
          class="batch-delete-btn"
          size="small"
      >
        批量移除
      </el-button>

      <el-table v-loading="loading"
                :data="transformedList"
                height="85vh"
                style="width: auto;"
                class="table-style" border
                highlight-selection-row="true"
                @selection-change="handleSelectionChange">
        <el-table-column
            type="selection"
            width="45">
        </el-table-column>

        <el-table-column
            prop="product_id"
            label="款号"
            min-width="120">
        </el-table-column>

        <el-table-column
            label="图片"
            min-width="120">
          <template slot-scope="scope">
            <!-- 当 scope.row.product_images 的长度小于 2 时，仅显示第一个图片 -->
            <el-image
                v-if="scope.row['product_images'].length < 2"
                style="width: 100px; height: 120px"
                :src="scope.row['product_images'][0]['name']"
                :preview-src-list="srcList"
                fit="fill">
            </el-image>
            <!-- 当 scope.row.product_images 的长度大于或等于 2 时，循环显示所有图片 -->
            <template v-else>
              <el-image
                  v-for="(image, index) in scope.row['product_images']"
                  :key="index"
                  style="width: 100px; height: 120px"
                  :src="image.name"
                  :preview-src-list="srcList"
                  fit="fill">
              </el-image>
            </template>
          </template>
        </el-table-column>

        <el-table-column
            prop="manufacture_files"
            label="生产文件"
            min-width="120">
          <template #default="scope">
    <span v-if="Array.isArray(scope.row['manufacture_files']) && scope.row['manufacture_files'].length > 0">
      <div v-for="(file, index) in scope.row['manufacture_files']" :key="index" class="file-link">
        <el-tooltip class="item" effect="dark" :content="file.name" placement="top">
          <a :href="file" target="_blank" class="link">
            {{ file.name.length > 10 ? file.name.slice(0, 10) + '...' : file.name }}
          </a>
        </el-tooltip>
      </div>
    </span>
          </template>
        </el-table-column>


        <el-table-column
            prop="product_type"
            label="产品类型">
        </el-table-column>

        <el-table-column
            prop="gender_type"
            label="男/女款">
        </el-table-column>

        <el-table-column
            prop="description"
            label="描述"
            :width="200">
        </el-table-column>

        <el-table-column
            label="操作"
            min-width="120">
          <template #default="scope">
            <div>
              <el-button plain @click="showDetails(scope.row)" size="small">查看详情</el-button>
            </div>
            <div style="margin-top: 5px;">
              <el-button type="danger" plain @click="removeVector(scope.row,scope.$index)" size="small">移除知识
              </el-button>
            </div>
          </template>
        </el-table-column>

        <!-- 模态框 -->
        <el-dialog :visible.sync="dialogVisible" title="产品详情" width="50%" :append-to-body="true">
          <el-form class="dialog-form" label-width="120px">
            <el-form-item label="款号:">
              <span class="custom-form-item">{{ selectedProduct.product_id }}</span>
            </el-form-item>
            <el-form-item label="产品类型:">
              <span class="custom-form-item">{{ selectedProduct.product_type }}</span>
            </el-form-item>
            <el-form-item label="男/女款:">
              <span class="custom-form-item">{{ selectedProduct.gender_type }}</span>
            </el-form-item>
            <el-form-item label="针型:">
              <span class="custom-form-item">{{ selectedProduct.needle_type }}</span>
            </el-form-item>
            <el-form-item label="克重:">
              <span class="custom-form-item">{{ selectedProduct.gram_weight }}</span>
            </el-form-item>
            <el-form-item label="材质:">
              <span class="custom-form-item">{{ selectedProduct.composition }}</span>
            </el-form-item>
            <el-form-item label="工艺师:" v-show="false">
              <span class="custom-form-item">{{ selectedProduct.technologist }}</span>
            </el-form-item>
            <el-form-item label="针法:">
              <span class="custom-form-item" style="padding-bottom: 50px;">{{ selectedProduct.pattern }}</span>
            </el-form-item>
            <el-form-item label="织机时间:">
              <span class="custom-form-item">{{ selectedProduct.knitting_time }}</span>
            </el-form-item>
            <el-form-item label="描述:">
              <span class="custom-form-item">{{ selectedProduct.description }}</span>
            </el-form-item>
          </el-form>

          <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
        </el-dialog>

      </el-table>
    </div>
  </div>
</template>

<style scoped>
.search-container {
  display: flex;
  width: 30%;
  height: auto;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #eee;
}

.search-result {
  position: relative;
  margin-left: 20px;
  width: auto;
  height: 80vh;
}

.el-form-item {
  /* 组件之间的间距 */
  margin-bottom: 5px;
}

.custom-switch-left-margin {
  /* 调整开关位置 */
  margin-left: -70px;
}

.center-line {
  /* 让时间日期中的-居中 */
  display: flex; /* 使用 Flexbox */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中，但在这里可能不是必需的，因为内容只有一行 */
  text-align: center; /* 文本居中，对于简单的文本节点更直接 */
  height: 100%; /* 这可能不起作用，除非你有特定的布局需求 */
}

.table-style {
  height: 100%;
  margin-left: 200px;
}

.img-preview {
  width: 100px;
  height: 150px;
  margin-top: 10px;
}

/* 生产文件超链接 */
.link {
  display: inline-block;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.dialog-form {
  margin-left: -15px;
  margin-right: 30px;
}

/* 批量删除按钮 */
.batch-delete-btn {
  position: absolute;
  right: -100px;
  top: 5px;
  z-index: 1;
}
</style>