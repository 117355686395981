<script>
// import $config from "@/conf";
// import AxiosClient  from "@/conf/index.js";
// import axios from "axios";
// import { useUserStore } from '@/store/user'
import { AxiosClient, config as $config } from "@/conf/index.js"; // 使用命名导入

export default {
  name: 'LoginView',
  data() {
    // 用户名规则
    const checkUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      }
      else {
        callback();
      }
    };
    // 密码规则
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        username: '',
        password: '',
      },
      // 校验
      rules: {
        username: [
          {validator: checkUsername, trigger: 'blur'}
        ],
        password: [
          {validator: validatePass, trigger: 'blur'}
        ],
      },
      userinfo:{}
    }
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        this.loginHandle();
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    loginHandle() {
      AxiosClient.getInstance().post($config.LOGIN_URL, this.ruleForm).then(response => {
        // console.log(response.data);
        if (response.status === 200) {
          this.$cookies.set("access_token", response.data['access_token'],'24h')
          this.$cookies.set("refresh_token", response.data['refresh_token'],'168h')

          this.userinfo = response.data;
          this.$store.commit('setUserData', {
            user: this.userinfo['username'],
            email: this.userinfo['email'],
            is_staff: this.userinfo['is_staff'],
          });


          this.$message({message: '登录成功', type: 'success'});
          this.$router.push('/')
          // console.log(this.$cookies.get('access_token'))
        }
      }).catch(error => {
        console.error('登录失败:', error.response ? error.response.data : error.message);
        this.$message({ message: '登录失败', type: 'error' });
      });
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyPress);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyPress);
  },
  components: {
  }
}
</script>

<template>
  <div class="main">
    <el-row :gutter="24">
      <el-col :span="8">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="8" id="main_bord" >
        <div class="grid-content-bg-purple-login">
          <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                   class="demo-ruleForm">
            <el-form-item label="用户名" prop="username">
              <el-input v-model.number="ruleForm.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="loginHandle" type="primary">登录</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>

      </el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple"></div>
      </el-col>
    </el-row>

  </div>
</template>

<style scoped>
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.grid-content-bg-purple-login{
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 200px;
  margin: 0 auto;
  padding-right: 50px;
  padding-top: 10px;
}
</style>
