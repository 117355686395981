import { AxiosClient, config as $config } from "@/conf/index.js";

export async function AutoVectorAPI() {
    return await AxiosClient.getInstance().get($config.AutoVectorURL);
}

export async function ClearVectorAPI() {
    return await AxiosClient.getInstance().get($config.DelAllVectorURL);
}

export async function DeleteVectorAPI(idList) {
    const res = {'error': false,'data': null,'message': ''}
    const idFormData = new FormData();
    idList.forEach(id => {
        idFormData.append('pk', id); // 使用 pk[] 作为键
    });
    console.log(idFormData);
    await AxiosClient.getInstance().post($config.DeleteVectorizationURL,idFormData, {
        headers: {
            'Content-Type': 'multipart/form-data' // 指定内容类型为 multipart/form-data
        }
    }).then(response => {
        res.data = response.data;
        res.message = "已提交删除请求";
    }).catch(error => {
        res.error = true;
        res.data = error;
        res.message = "知识库删除失败";
    });

    // await axios({
    //     withCredentials: true,
    //     method: 'post',
    //     url: $config.DeleteVectorizationURL,
    //     data: idFormData,
    // }).then(response => {
    //     res.data = response.data;
    //     res.message = "已提交删除请求";
    // }).catch(error => {
    //     res.error = true;
    //     res.data = error;
    //     res.message = "知识库删除失败";
    // });
    return (res);
}

export async function AddVectorAPI(idList) {
    const res = {'error': false,'data': null,'message': ''}
    const idFormData = new FormData();
    idFormData.append('pk', idList);
    await AxiosClient.getInstance().post($config.OneVectorizationURL,idFormData, {
        headers: {
            'Content-Type': 'multipart/form-data' // 指定内容类型为 multipart/form-data
        }
    }).then(response => {
        res.data = response.data;
        res.message = "已提交知识库";
    }).catch(error => {
        res.error = true;
        res.data = error;
        res.message = "知识库上传失败";
    });
    // await axios({
    //     withCredentials: true,
    //     method: 'post',
    //     url: $config.OneVectorizationURL,
    //     data: idFormData,
    // }).then(response => {
    //     res.data = response.data;
    //     res.message = "已提交知识库";
    // }).catch(error => {
    //     res.error = true;
    //     res.data = error;
    //     res.message = "知识库上传失败";
    // });
    return res;
}