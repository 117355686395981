<script>
export default {
  name: 'sidebarComponent',
  data() {
    return {
      activeIndex: '1' // 默认活动项
    };
  },
  created() {
    // 从本地存储读取活动项索引
    const savedIndex = localStorage.getItem('activeMenuIndex');
    if (savedIndex) {
      this.activeIndex = savedIndex;
    } else {
      // 根据当前路由设置默认活动项
      this.setActiveIndexByRoute(this.$route.path);
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // handleClick(key) {
    //   if (this.$route.path !== key) {
    //     this.$router.push(key);
    //   }
    // },
    handleClick(path, index) {
      this.activeIndex = index;
      localStorage.setItem('activeMenuIndex', index); // 保存活动项索引
      this.$router.push(path); // 导航到指定路径
    },
    setActiveIndexByRoute(path) {
      switch (path) {
        case '/multimodelsearch':
          this.activeIndex = '1';
          break;
        case '/createproducts':
          this.activeIndex = '2';
          break;
        case '/products':
          this.activeIndex = '3';
          break;
        case '/backup':
          this.activeIndex = '4';
          break;
        default:
          this.activeIndex = '1';
      }
    },
  }
}
</script>

<template>
  <div>
    <el-row class="tac">
      <el-col :span="12">
        <el-menu
            :default-active="activeIndex"
            class="el-menu-vertical-demo custom-menu-width"
            @open="handleOpen"
            @close="handleClose">
          <el-menu-item index="1" @click="handleClick('/multimodelsearch', '1')">
            <template slot="title">
              <i class="el-icon-search"></i>
              <span>搜索知识库</span>
            </template>
          </el-menu-item>
          <el-menu-item index="2" @click="handleClick('/createproducts', '2')">
            <template slot="title">
              <i class="el-icon-circle-plus-outline"></i>
              <span>添加样品</span>
            </template>
          </el-menu-item>
          <el-menu-item index="3" @click="handleClick('/products', '3')">
            <i class="el-icon-document"></i>
            <span slot="title">样品库</span>
          </el-menu-item>
          <el-menu-item index="4" @click="handleClick('/backup', '4')">
            <i class="el-icon-setting"></i>
            <span slot="title">备用导航</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col :span="12">
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.custom-menu-width {
  width: 150px; /* 或者你需要的任何宽度 */
  //text-align: center;
}
</style>