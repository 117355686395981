<script>
import {config as $config} from "@/conf/index.js";
import {NeedleTypes, ProductTypes, Yesterday_Today_Week} from "@/assets/Constants";
import {CreateProductAPI} from "@/util/GarmentCURD";
import {AddVectorAPI} from "@/util/VectorAPI";

export default {
  name: 'CreateProducts',
  computed: {
    $config() {
      return $config
    }
  },
  created() {
  },
  data() {
    return {
      responseData: null,
      form: {
        knitting_time: null,
        manufactured_at: null,
        v_status: false,
      },
      GenderTypes: ['男装', '女装'],
      ProductTypes: ProductTypes,
      NeedleTypes: NeedleTypes,
      pickerOptions: Yesterday_Today_Week,
      imgPreview: null,
      fileList: [],
      fileList2: [],
      ExcelFileList: [],

    }
  },
  methods: {
    handlePreview(file) {
      console.log('preview', file);
    },
    handleRemove(file, fileList) {
      console.log('file removed', file, fileList);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
      console.log(this.fileList.length);
    },
    handleChange2(file, fileList) {
      this.fileList2 = fileList;
      console.log(this.fileList2.length);
    },
    resetForm() {
      this.fileList = [];
      this.fileList2 = [];
      this.form = {}
    },
    removeEmptyValue(obj) {
      for (let key in obj) {
        if (obj[key] === null || obj[key] === 0 || obj[key] === undefined || obj[key] === "" || Number.isNaN(obj[key])) {
          delete obj[key];
        }
      }
      return obj;
    },
    async createProductHandler(formData, v_status) {
      for (const [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      const createProductRes = await CreateProductAPI(formData)
      console.log(createProductRes);
      if (createProductRes.status === 201) {
        if (v_status) {
          await AddVectorAPI([createProductRes.data.data.id])
          this.$message.success('创建产品成功，已进行向量化');
        } else {
          this.$message.success('创建产品成功，但未进行向量化');
        }
      }
    },
    async onSubmit() {
      console.log(this.fileList);
      this.submitData = this.removeEmptyValue(this.form);
      const formData = new FormData();
      for (const [key, value] of Object.entries(this.submitData)) {
        formData.append(key, value);
      }
      if (this.fileList) {
        for (let i = 0; i < this.fileList.length; i++) {
          formData.append('product_images', this.fileList[i].raw);
        }
      }
      if (this.fileList2) {
        for (let i = 0; i < this.fileList2.length; i++) {
          formData.append('manufacture_files', this.fileList2[i].raw);
        }
      }
      await this.createProductHandler(formData, this.form.v_status)
      // 延迟一秒刷新页面
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
    handleSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      if (response.status === 'success') {
        this.$message.success(response.detail);
      } else {
        this.$message.error(response.detail);
      }
    },
  }

}
</script>

<template>
  <div class="create-products-container">


    <el-form ref="form" :model="form" label-width="80px" label-position="right">
      <el-row style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
        <el-col :span="6">
          <el-form-item label="自动上传">
            <el-switch v-model="form.v_status"></el-switch>
          </el-form-item>

          <el-form-item label="款号">
            <el-input class="product-type-item" v-model="form.product_id" style="width: 200px;"></el-input>
          </el-form-item>

          <el-form-item label="产品类型">
            <el-select v-model="form.product_type" placeholder="选择产品类型">
              <el-option
                  v-for="item in ProductTypes"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="男/女款">
            <el-select v-model="form.gender_type" placeholder="选择款式类型">
              <el-option
                  v-for="item in GenderTypes"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="针型">
            <el-select v-model="form.needle_type" placeholder="选择针型">
              <el-option
                  v-for="item in NeedleTypes"
                  :key="item"
                  :label="item"
                  :value="item">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="克重">
            <el-col :span="5">
              <el-input-number v-model="form.gram_weight" :precision="2" :step="0.1" :max="10000"></el-input-number>
            </el-col>
          </el-form-item>

          <el-form-item class="product-item" label="材质">
            <el-input v-model="form.composition" style="width: 300px;"></el-input>
          </el-form-item>

          <el-form-item class="product-item" label="工艺师">
            <el-input v-model="form.technologist" style="width: 300px;"></el-input>
          </el-form-item>

          <el-form-item class="product-item" label="针法">
            <el-input v-model="form.pattern" style="width: 300px;"></el-input>
          </el-form-item>

          <el-form-item label="织机时间(分钟）">
            <el-col :span="5">
              <el-input-number v-model="form.knitting_time" :precision="2" :step="1" :max="10000"></el-input-number>
            </el-col>
          </el-form-item>

          <el-form-item label="生产日期">
            <div class="block">
              <el-date-picker
                  v-model="form.manufactured_at"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  :picker-options="pickerOptions"
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
          </el-form-item>
        </el-col>

        <!-- 换行: 新的部分从这开始 -->
        <el-col :span="12">

          <el-form-item class="product-item" label="描述">
            <el-input class="product-description-item" type="textarea" v-model="form.description"
                      style="width: 300px;"></el-input>
          </el-form-item>

          <el-form-item label="上传图片">
            <el-upload
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :on-change="handleChange"
                :auto-upload="false"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                list-type="picture-card"
                accept="image/*">
              <el-button slot="trigger" size="small" type="primary">选取图片</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="上传文件">
            <el-upload
                class="upload-demo"
                action=""
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList2"
                :on-change="handleChange2"
                :auto-upload="false"
                multiple
                :limit="3"
                :on-exceed="handleExceed"
                list-type="picture"
                accept="">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
            </el-upload>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-upload
              class="upload-Excel-demo"
              name="Excel_File"
              :action=$config.ExcelUploadURL
              :with-credentials=true
              :on-success="handleSuccess"
              multiple
              :file-list="ExcelFileList">
            <el-button size="small">Excel批量导入</el-button>
          </el-upload>
        </el-col>

      </el-row>

      <el-row>
        <el-col :span="24">
          <el-form-item>
            <el-button type="primary" @click="onSubmit" style="margin-top: 15px;">创建</el-button>
            <el-button @click="resetForm()">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>


  </div>
</template>

<style scoped>
.create-products-container {
  margin-top: 20px;
  width: 100%;
}

.product-type-item {
  width: 17%;
}

.product-item {
  width: 40%;
}

.upload-demo {
  width: 500px;
  height: auto;
}

.upload-Excel-demo {
  text-align: right;
}
</style>