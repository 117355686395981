import { AxiosClient, config as $config } from "@/conf/index.js";

export async function QueryAllGarmentAPI(currentPage, maxSize, orderBy, currentSearch) {
    return await AxiosClient.getInstance().get($config.QueryAllGarmentURL, {
        params: {
            page: currentPage,
            size: maxSize,
            ordering: orderBy,
            search: currentSearch
        }
    });
}

export async function UpdateProductAPI(formData, id) {
    const res = { 'error': false, 'data': null, 'message': '' };
    await AxiosClient.getInstance().put(`${$config.QueryAllGarmentURL}${id}/`, formData)
        .then(response => {
            res.data = response.data;
            res.message = "产品更新成功";
        }).catch(error => {
            res.error = true;
            res.data = error;
            res.message = "产品更新失败";
        });
    return res;
}

export async function DeleteProductAPI(id) {
    const res = { 'error': false, 'data': null, 'message': '' };

    await AxiosClient.getInstance().delete(`${$config.DeleteProductURL}${id}/`)
        .then(response => {
            res.data = response.data;
            res.message = "删除成功";
        }).catch(error => {
            res.error = true;
            res.data = error;
            res.message = "删除失败";
        });
    return res;
}

export async function CreateProductAPI(formData) {
    console.log(formData.get('product_images'));
    return await AxiosClient.getInstance().post($config.CreateProductURL, formData, {
        headers: {
            'Content-Type': 'multipart/form-data' // 指定内容类型为 multipart/form-data
        }
    })
}