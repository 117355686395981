// conf/index.js
// const ENVIRONMENT = 'develop';
const ENVIRONMENT = 'staging';
const BASEURL = ENVIRONMENT === 'develop' ? 'http://127.0.0.1:8000/' : 'https://stage.backend.ai.labull.org.cn/';
const config = {
    BASEURL,
    LOGIN_URL: BASEURL + 'core/login/',
    LOGOUT_URL: BASEURL + 'core/logout/',
    CHANGE_PASSWORD_URL: BASEURL + 'core/change_password/',
    MultimodelSearchURL: BASEURL + 'multimodel_search_service/product_knowledge_search/',
    QueryAllGarmentURL: BASEURL + 'multimodel_search_service/garment_knowledges/',
    CreateProductURL: BASEURL + 'multimodel_search_service/garment_knowledges/',
    DeleteProductURL: BASEURL + 'multimodel_search_service/garment_knowledges/',
    OneVectorizationURL: BASEURL + 'multimodel_search_service/opensearch_vectorization_create/',
    DeleteVectorizationURL: BASEURL + 'multimodel_search_service/opensearch_vectorization_destroy/',
    UpdateVectorizationURL: BASEURL + 'multimodel_search_service/opensearch_vectorization_upload/',
    AutoVectorURL: BASEURL + 'multimodel_search_service/opensearch_vectorization_create/',
    DelAllVectorURL: BASEURL + 'multimodel_search_service/delete_all_vectorizer/',
    FileUploadURL: BASEURL + 'multimodel_search_service/file_upload/',
    ImageUploadURL: BASEURL + 'multimodel_search_service/image_upload/',
    ExcelUploadURL: BASEURL + 'multimodel_search_service/upload_excel/',
};

import axios from 'axios';
import Vue from 'vue';
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);

const Cookies = Vue.$cookies;
const accessToken = Cookies.get('access_token') || '';

class AxiosClient {
    static instance;

    static getInstance() {
        if (!this.instance) {
            this.instance = axios.create({
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                withCredentials: true,
            });
            if (accessToken){
                this.instance.defaults.headers['access_token'] = accessToken;
            }
        }
        return this.instance;
    }
}

export {config, AxiosClient}; // 使用命名导出
