<template>
  <div class="container">
    <!-- Header 区域 -->
    <header class="header">
      <headerComponent></headerComponent>
    </header>

    <!-- Sidebar 和 AppMain 区域 -->
    <div class="content">
      <aside class="sidebar">
        <sidebarComponent></sidebarComponent>
      </aside>

      <main class="app-main">
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>

<script>
import headerComponent from "@/components/headerComponent.vue";
import sidebarComponent from "@/components/sidebarComponent.vue";

export default {
  name: 'HomeView',
  components: {
    headerComponent,
    sidebarComponent
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 93vh; /* 占据整个视窗高度 */
  margin: 0; /* 移除任何默认的外边距 */
  padding: 0; /* 移除任何默认的内边距 */
}

.header {
  padding: 10px 20px;
  text-align: center;
  height: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  z-index: 10; /* 保证header在sidebar之上 */
}

.content {
  display: flex; /* 使sidebar和app-main水平排列 */
  flex-grow: 1; /* 占据header下方的剩余空间 */
  height: 93vh; /* 需要根据header的实际高度调整 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.sidebar {
  width: 150px; /* 设定sidebar的宽度 */
  height: 93vh; /* 占据content的整个高度 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.app-main {
  flex-grow: 1; /* 占据sidebar右侧的所有剩余空间 */
  padding-right: 5px;
  padding-left: 15px;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  overflow-y: auto; /* 允许app-main内容滚动 */
}
/* 注意：这里“header的高度”需要根据header组件的实际高度来设置，或者使用其他方法来动态计算 */
</style>
