<script>
import {config as $config} from "@/conf/index.js"; // 使用命名导入
import {NeedleTypes, OrderDict, ProductTypes, Week_Month_ThreeMonths} from "@/assets/Constants";
import {QueryAllGarmentAPI, UpdateProductAPI, DeleteProductAPI} from "@/util/GarmentCURD";
import {AutoVectorAPI, ClearVectorAPI, DeleteVectorAPI, AddVectorAPI} from "@/util/VectorAPI";
import {DeleteFileAPI, DeleteImageAPI} from "@/util/FileCURD";


export default {
  name: 'AllProducts',
  computed: {
    $config() {
      return $config
    },
    uploadDataID() {
      return {
        id: this.selectedProduct.id // 返回当前产品的pk
      };
    },
  },

  async created() {
    console.log('created');
    this.allProductsRes = await QueryAllGarmentAPI(this.currentPage, this.maxSize, this.orderBy, this.currentSearch)
    console.log(this.allProductsRes);
    try {
      // 查询产品数据
      this.products = this.allProductsRes.data.results;
      // console.log(this.products[0].product_images);
      this.count = this.allProductsRes.data.count;
      this.maxPages = Math.ceil(this.count / this.maxSize)

      for (let i = 0; i < this.products.length; i++) {
        for (let j = 0; j < this.products[i]['product_images'].length; j++) {
          if (!this.products[i]['product_images'][j].includes('undefined')) {
            // 把所有图片的url解析转化成File对象存入templist，handleEdit()触发时加入每二个fileList的raw属性中
            const file = await this.getImageAsFile(this.products[i]['product_images'][j], this.products[i]['product_images'][j].split('/').pop());
            this.tempList.push(file);
          }
        }
        // console.log(this.tempList); // 这里每次循环后都会打印当前的 tempList
        this.products[i].isExpanded = false;
        // 如果你不需要在每次循环后都清空 tempList，可以移除 tempList = [];
      }

    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      count: 0, // 产品总数
      maxSize: 5, // 每页显示的最大数量
      maxPages: 0, // 总页数
      currentPage: 1, // 当前页数
      orderBy: '-is_vectorized', // 排序字段 前面加负号表示倒序
      currentSearch: '', // 当前搜索条件
      products: [],
      dialogVisible: false,// 编辑弹窗 默认关闭
      selectedProduct: {},// 展示详情弹窗中被选中的对象
      form: {},// 编辑弹窗表单

      GenderTypes: ['男装', '女装'],
      ProductTypes: ProductTypes,
      NeedleTypes: NeedleTypes,
      pickerOptions: Week_Month_ThreeMonths,
      tempList: [],
      fileList: [],
      fileList2: [],
      submitData: {},
      multipleSelection: [],
      fileListURL: [],
      showTechnologist: false, //默认不展示工艺师
    }
  },
  methods: {
    async handleCurrentChange(page) {
      // 分页器
      this.currentPage = page;
      try {
        const response = await QueryAllGarmentAPI(this.currentPage, this.maxSize, this.orderBy, this.currentSearch);
        // 查询产品数据
        this.products = response.data.results;
        this.count = response.data.count;
        this.maxPages = Math.ceil(this.count / this.maxSize)

        for (let i = 0; i < this.products.length; i++) {
          for (let j = 0; j < this.products[i]['product_images'].length; j++) {
            if (!this.products[i]['product_images'][j].includes('undefined')) {
              // 把所有图片的url解析转化成File对象存入templist，handleEdit()触发时加入每二个fileList的raw属性中
              const file = await this.getImageAsFile(this.products[i]['product_images'][j], this.products[i]['product_images'][j].split('/').pop());
              this.tempList.push(file);
            }
          }
          // console.log(this.tempList); // 这里每次循环后都会打印当前的 tempList
          this.products[i].isExpanded = false;
          // 如果你不需要在每次循环后都清空 tempList，可以移除 tempList = [];
        }

      } catch (error) {
        console.log(error);
      }
    },
    handleSizeChange(size) {
      // 切换每页显示数量
      this.maxSize = size;
      this.handleCurrentChange(1);
    },
    async getImageAsFile(url, fileName = 'image.png') {
      if (!url) {
        return null;
      }
      try {
        const response = await fetch(url);
        if (!response.ok) {
          // 抛出更具体的错误，包括状态码
          throw new Error(`Network response was not ok. Status: ${response.status}`);
        }

        const blob = await response.blob();
        return new File([blob], fileName, {type: blob.type});
      } catch (error) {
        // 在控制台中记录错误和出错的 URL
        console.error(`Error fetching image from ${url}:`, error);
        // 你可以选择在这里返回一个默认值或 null，或者重新抛出错误
        throw error; // 或者 return null;
      }
    },
    handlePreview(file) {
      console.log('preview', file);
    },
    changeOrder(ordering) {
      console.log(ordering);
      if (ordering === this.orderBy) {
        this.orderBy = this.orderBy.startsWith('-') ? this.orderBy.slice(1) : `-${this.orderBy}`;
      } else {
        this.orderBy = ordering;
      }
      this.handleCurrentChange(1);
    },
    handleSortChange({column}) {
      this.changeOrder(OrderDict[column['label']])
    },
    handleSearchInput(value) {
      this.currentSearch = value;
      this.handleCurrentChange(1);
    },
    handleSuccess(response) {
      const res = {name: response.data.name, url: response.data.url}
      this.fileListURL.push(res);
      console.log(response.data);
    },
    handleRemove(file, fileList) {
      console.log('image removed', file, fileList);
      this.fileList = this.fileList.filter(f => f.name !== file.name || f.uid !== file.uid);
      DeleteImageAPI(file.id);
    },
    handleRemove2(file, fileList) {
      console.log('file removed', file, fileList);
      this.fileListURL = this.fileListURL.filter(f => f.name !== file.name || f.uid !== file.uid);
      DeleteFileAPI(file.id);
    },
    handleChange(file, fileList) {
      console.log(this.fileList)
      this.fileList = fileList;
      console.log(this.fileList.length);
      console.log(this.fileList);
    },
    handleFileChange(file, fileList) {
      console.log(file);
      console.log(fileList);
    },

    expandDescription(index) {
      console.log(index);
      this.$set(this.products[index], 'isExpanded', true); // 设置为 true，不切换
      console.log(this.products[index].isExpanded);
    },
    handleEdit(row) {
      // console.log(row['product_images']);
      // console.log(row['manufacture_files']);
      this.selectedProduct = row;
      this.dialogVisible = true;
      this.fileList = row['product_images'].map(url => ({
        id: url.id,
        name: url.name.split('/').pop(), // 假设URL的最后一部分是文件名
        url: url.name,
        raw: this.tempList.find(f => f.name === url.name.split('/').pop())
      }));
      this.fileListURL = row['manufacture_files'].map(url => ({
        id: url.id,
        name: url.name.split('/').pop(), // 假设URL的最后一部分是文件名
        url: url.name,
      }));
      console.log(this.tempList);
    },
    removeEmptyValue(obj) {
      for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "" || Number.isNaN(obj[key]) || key === "product_images"
            || key === "manufacture_files") {
          delete obj[key];
        }
      }
      return obj;
    },
    async handleDeleteVectorization(row, index) {
      console.log(row, index);
      console.log(row, index);
      const res = await DeleteVectorAPI([row.id])
      console.log(res.error);
      console.log(res.data);
      this.$message.success(res.message);
    },
    async handleAddVectorization(row, index) {
      if (row['product_images'].length === 0) {
        this.$message.error("请先上传产品图片");
        return;
      }
      console.log(row, index);
      const res = await AddVectorAPI([row.id])

      console.log(res.error);
      console.log(res.data);
      this.$message(res.message);
      // this.products[index]['is_vectorized'] = true;
    },
    async handleDelete(row, index) {
      const idFormData = new FormData();
      idFormData.append('pk', [row.id]);
      console.log(row);
      this.$confirm('知识库的内容也会被同时删除，确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // 没有向量化的数据直接删除
        if (!row['is_vectorized']) {
          const res = await DeleteProductAPI(row.id);
          console.log(res.error);
          console.log(res.data);
          this.$message.success(res.message);
          if (index) {
            this.products.splice(index, 1);
          }
        }
        // 先删除向量化数据，再删除产品数据
        else {
          const vecRes = await DeleteVectorAPI([row.id])
          console.log(vecRes.error);
          console.log(vecRes.data);
          this.$message.success(vecRes.message);
          if (!vecRes.data.error) {
            const pRes = await DeleteProductAPI(row.id)
            console.log(pRes.error);
            console.log(pRes.data);
            this.$message.success(pRes.message);
            if (index) {
              this.products.splice(index, 1);
            }
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async updateSubmit() {
      if (this.selectedProduct['is_vectorized']) {
        await DeleteVectorAPI(this.selectedProduct.id)
      }
      this.submitData = this.removeEmptyValue(this.selectedProduct);
      const formData = new FormData();
      for (const [key, value] of Object.entries(this.submitData)) {
        formData.append(key, value);
      }

      const res = await UpdateProductAPI(formData, this.selectedProduct.id)
      if (res.error) {
        this.$message.error(res.message);
      } else {
        this.$message.success(res.message);
        this.dialogVisible = false;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(`selected: ${val.length} items`, val);
      // console.log(this.multipleSelection);
    },
    handleBatchDeleteVector() {
      console.log(this.multipleSelection);
      this.$confirm('确认批量删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const row = this.multipleSelection[i];
          console.log(this.multipleSelection[i]);
          if (!row['is_vectorized']) {
            DeleteProductAPI(row.id);
          }
          // 先删除向量化数据，再删除产品数据
          else {
            const vecRes = DeleteVectorAPI([row.id])
            if (!vecRes.data.error) {
              DeleteProductAPI(row.id)
            }
          }
        }
        this.$message.success('删除成功');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleAutoVector() {
      const autoVectorRes = AutoVectorAPI()
      if (autoVectorRes) {
        this.$message.success('开始自动上传');
      } else {
        this.$message.error('自动上传失败，请联系管理员');
      }

    },
    handleDelAllVector() {
      this.$confirm('确认清空知识库?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const autoVectorRes = ClearVectorAPI()
        console.log(autoVectorRes.data);
        this.$message.success('清空成功');
      })
    },
  },
}
</script>

<template>
  <div class="all-products-container">

    <div class="block">
      <el-pagination
          class="pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20, 50, 100]"
          :page-size="maxSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count">
      </el-pagination>

    </div>
    <el-input
        class="search-input"
        v-model="currentSearch"
        @change="handleSearchInput"
        placeholder="搜索款号">
    </el-input>
    <el-button
        class="del-all-vector-btn"
        @click="handleDelAllVector()"
        size="small">
      清空知识库
    </el-button>
    <el-button
        class="auto-upload-btn"
        @click="handleAutoVector()"
        size="small">
      自动上传知识库
    </el-button>

    <el-button
        v-if="multipleSelection.length > 0"
        type="danger" @click="handleBatchDeleteVector()" class="batch-delete-btn"
        size="small">
      批量删除
    </el-button>
    <el-button
        v-else
        @click="handleBatchDeleteVector()"
        class="batch-delete-btn"
        size="small">
      批量删除
    </el-button>

    <!--表格主体-->
    <el-table
        class="table-border"
        :data="products"
        height="800"
        border
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange">

      <el-table-column
          label=" "
          type="selection"
          width=40>
      </el-table-column>
      <el-table-column
          label=" "
          type="index"
          width=50>
      </el-table-column>
      <el-table-column
          prop="product_id"
          label="款号"
          min-width=3>
      </el-table-column>
      <el-table-column
          label="图片"
          min-width=4>
        <template slot-scope="scope">
          <div v-if="scope.row['product_images'] && scope.row['product_images'].length > 0">
            <el-image
                v-for="(image, index) in scope.row['product_images']"
                :key="index"
                style="width: 100px; height: 120px"
                :preview-src-list="scope.row['product_images'].map(img => img.name)"
                :src="image.name"
                fit="fill">
            </el-image>
          </div>

          <div v-else class="block">
            <el-image>
              <span slot="error">
                无图片
              </span>
            </el-image>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="manufacture_files"
          label="生产文件"
          min-width=4>
        <template #default="scope">
          <span v-if="Array.isArray(scope.row.manufacture_files) && scope.row.manufacture_files.length > 0">
            <div v-for="(file, index) in scope.row.manufacture_files" :key="index" class="file-link">
              <el-tooltip class="item" effect="dark" :content="file.name" placement="top">
                <a :href="file.name" target="_blank" class="link">
                  {{ file.name.length > 25 ? file.name.slice(0, 25) + '...' : file.namef }}
                    <el-divider></el-divider>
                </a>

              </el-tooltip>
            </div>
          </span>
        </template>
      </el-table-column>

      <el-table-column
          prop="product_type"
          label="产品类型"
          min-width=3>
      </el-table-column>
      <el-table-column
          prop="gender_type"
          sortable="custom"
          label="男/女款"
          min-width=4>
      </el-table-column>
      <el-table-column
          prop="needle_type"
          label="针型"
          min-width=3>
      </el-table-column>
      <el-table-column
          label="知识库状态"
          sortable="custom"
          min-width=2>
        <template v-slot="scope">
          <el-progress v-if="!scope.row.is_vectorized" type="circle" :percentage="100" :width="20"
                       status="exception"></el-progress>
          <el-progress v-else type="circle" :percentage="100" :width="20" status="success"></el-progress>
        </template>
      </el-table-column>
      <el-table-column
          prop="composition"
          label="材质"
          min-width=4>
      </el-table-column>
      <el-table-column
          prop="gram_weight"
          sortable="custom"
          label="克重"
          min-width=3>
      </el-table-column>
      <el-table-column
          prop="manufactured_at"
          sortable="custom"
          label="生产日期"
          min-width=3>
      </el-table-column>
      <el-table-column
          prop="knitting_time"
          sortable="custom"
          label="织机时间(分)"
          min-width=3>
      </el-table-column>
      <el-table-column
          prop="description"
          label="描述"
          min-width=6>
        <template #default="scope">
          <div v-if="scope.row.description">
            <div v-if="scope.row.description.length > 50">
              <span v-if="scope.row.isExpanded">{{ scope.row.description }}</span>
              <span v-else>{{
                  scope.row.description.slice(0, 50) + (scope.row.description.length > 50 ? '...' : '')
                }}</span>
              <el-button v-if="!scope.row.isExpanded && scope.row.description.length > 50" type="text"
                         @click="expandDescription(scope.$index)">
                展开
              </el-button>
            </div>
            <span v-else>{{ scope.row.description }}</span>
          </div>
          <div v-else class="block">
            <span></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="pattern"
          label="针法"
          min-width=3>
      </el-table-column>
      <el-table-column
          prop="technologist"
          label="工艺师"
          min-width=4
      v-if="showTechnologist">
      </el-table-column>
      <el-table-column
          label="操作"
          min-width=5>
        <template #default="scope">
          <div>
            <el-button @click="handleEdit(scope.row)" type="text" size="medium">编辑</el-button>
          </div>
          <div style="margin-top: 5px;">
            <el-button @click="handleDelete(scope.row,scope.$index)" type="text" size="medium">删除</el-button>
          </div>
          <div v-if="scope.row['is_vectorized']" style="margin-top: 5px;">
            <el-button @click="handleDeleteVectorization(scope.row,scope.$index)" type="text" size="medium">移出知识库
            </el-button>
          </div>
          <div v-else style="margin-top: 5px;">
            <el-button @click="handleAddVectorization(scope.row,scope.$index)" type="text" size="medium">添加至知识库
            </el-button>
          </div>
        </template>
      </el-table-column>

    </el-table>

    <!-- 模态框 -->
    <el-dialog :visible.sync="dialogVisible" title="产品详情" width=50% :append-to-body="true">

      <el-form ref="form" :model="form" label-width="80px" label-position="right">
        <el-form-item label="款号">
          <el-input class="product-type-item" v-model="selectedProduct.product_id"></el-input>
        </el-form-item>
        <el-form-item label="产品类型">
          <el-select v-model="selectedProduct.product_type" placeholder="选择产品类型">
            <el-option
                v-for="item in ProductTypes"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="男/女款">
          <el-select v-model="selectedProduct.gender_type" placeholder="选择款式类型">
            <el-option
                v-for="item in GenderTypes"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="针型">
          <el-select v-model="selectedProduct.needle_type" placeholder="选择针型">
            <el-option
                v-for="item in NeedleTypes"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="克重">
          <el-col :span="5">
            <el-input-number v-model="selectedProduct.gram_weight" :precision="2" :step="0.1"
                             :max="10000"></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item class="product-item" label="材质">
          <el-input v-model="selectedProduct.composition"></el-input>
        </el-form-item>
        <el-form-item class="product-item" label="针法">
          <el-input v-model="selectedProduct.pattern"></el-input>
        </el-form-item>
        <el-form-item label="工艺师" v-show="false">
          <el-input class="product-type-item" v-model="selectedProduct.technologist"></el-input>
        </el-form-item>
        <el-form-item label="织机时间(分钟）">
          <el-col :span="5">
            <el-input-number v-model="selectedProduct.knitting_time" :precision="2" :step="1"
                             :max="10000"></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item label="生产日期">
          <div class="block">
            <el-date-picker
                v-model="selectedProduct.manufactured_at"
                align="right"
                type="date"
                placeholder="选择日期"
                :picker-options="pickerOptions"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </el-form-item>
        <el-form-item class="product-item" label="描述">
          <el-input class="product-description-item" type="textarea" v-model="selectedProduct.description"></el-input>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-upload
              class="upload-demo"
              :action=$config.ImageUploadURL
              :data="uploadDataID"
              :with-credentials=true
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              :on-change="handleChange"
              multiple
              :limit="3"
              list-type="picture-card"
              accept="image/*">
            <el-button slot="trigger" size="small" type="primary">选取图片</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>

        <el-form-item label="上传文件">
          <el-upload
              class="upload-demo"
              :action=$config.FileUploadURL
              :data="uploadDataID"
              name="file"
              :with-credentials=true
              :on-success="handleSuccess"
              :on-preview="handlePreview"
              :on-remove="handleRemove2"
              multiple
              :on-change="handleFileChange"
              :file-list="fileListURL">
            <el-button size="small" type="primary">上传文件</el-button>
          </el-upload>
        </el-form-item>


        <el-form-item class="update-btn">
          <el-button type="primary" @click="updateSubmit()" style="margin-top: 15px;">确认</el-button>
        </el-form-item>
      </el-form>


      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped>
.search-input {
  width: 200px;
  position: absolute;
  right: 400px;
  margin-top: 15px;
}

.pagination {
  position: absolute;
  left: 200px;
  margin-top: 20px;
}

.table-border {
  width: 98%;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid #eee;
}

/*删除全部向量化*/
.del-all-vector-btn {
  margin-left: 86.8%;
  margin-top: 20px;
}

/*自动上传*/
.auto-upload-btn {
  position: absolute;
  right: 250px;
  margin-top: 20px;
}

/*批量删除*/
.batch-delete-btn {
  position: absolute;
  right: 50px;
  margin-top: 20px;
}

.update-btn {
  position: absolute;
  right: 100px;
  bottom: -1px;
}
</style>