<script>
import {HelpText} from "@/assets/Constants";
import {ChangePasswordAPI} from "@/util/coreAPI";

export default {
  name: 'headerComponent',
  data() {
    return {
      user: this.$store.state.userinfo.user,
      markdownContent: HelpText,
      helpTextDialogVisible: false,
      changePasswordDialogVisible: false,
      errors: [],
      form: {
        password: "",  // 修改密码输入框
      },
    }
  },
  methods: {
    logout() {
      this.$cookies.remove('access_token')
      this.$cookies.remove('refresh_token')
      this.$store.dispatch('logout')
      location.reload();
    },
    open_help_text() {
      this.helpTextDialogVisible = true;
    },
    changePassword() {
      this.changePasswordDialogVisible = true;
    },
    async uploadPassword() {
      const formData  = new FormData();
      const password = this.form.password;
      formData.append('password', password);
      const res = await ChangePasswordAPI(formData)
      console.log(res);
      if (res.error) {
        console.log(res.message);
        this.errors = res.message.split(',');
      } else {
        console.log(res);
        this.$message.success(res.message);
        this.logout();
      }

    },

  },
  created() {
  }
}
</script>

<template>
  <div class="header-container">
    <el-row>
      <el-col :span="4">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light"></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light"></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple"></div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple-light">

          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              {{ user }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="open_help_text" icon="el-icon-caret-right">帮助文档</el-dropdown-item>
              <el-dropdown-item @click.native="changePassword" icon="el-icon-caret-right">修改密码</el-dropdown-item>
              <el-dropdown-item @click.native="logout" icon="el-icon-caret-right">退出登陆</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>

    <el-dialog
        :append-to-body="true"
        :visible.sync="helpTextDialogVisible"
        width="80%">
      <div v-html="markdownContent"></div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="helpTextDialogVisible = false">关 闭</el-button>
  </span>
    </el-dialog>

    <el-dialog
        :append-to-body="true"
        :visible.sync="changePasswordDialogVisible"
        width="20%"
        @keydown.native.enter.prevent="uploadPassword">
      <div class="password-input">
        <!-- 密码输入框 -->
        <el-form :model="form" ref="form" label-width="50px">
          <el-form-item
              label="密码"
          >
            <el-input
                v-model="form.password"
                type="password"
                placeholder="请输入新密码"
                show-password
            ></el-input>
          </el-form-item>
        </el-form>

        <!-- 错误提示信息，显示所有错误 -->
        <div v-if="errors.length">
          <el-alert
              v-for="(error, index) in errors"
              :key="index"
              type="error"
              :title="error"
              show-icon
              class="error-alert"
          ></el-alert>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="uploadPassword" type="primary">修 改</el-button>
        <el-button @click="changePasswordDialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.header-container {
  align-items: flex-end; /* 让子元素靠下对齐 */
}

.bg-purple-light {
  text-align: right; /* 文字靠右对齐 */
}

.grid-content {
  border-radius: 4px;
  min-height: 70px;
  text-align: right; /* 文字靠右对齐 */
  position: relative; /* 设置为相对定位，作为下拉菜单绝对定位的参照 */
}

.grid-content .el-dropdown {
  bottom: 0; /* 紧贴父容器的底部 */
  right: 0; /* 紧贴父容器的右侧 */
  /* 如果需要，还可以添加一些额外的样式，比如宽度 */
  width: 100%; /* 或者你希望的宽度 */
  padding-bottom: 5px; /* 内边距 */
  padding-left: 5px; /* 内边距 */
}

.grid-content {
  min-height: 45px; /* header中每个el-col的高度 */
}
</style>