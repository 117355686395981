// store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedstate  from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userinfo: null
    },
    mutations: {
        setUserData(state, data) {
            state.userinfo = data;
        },
        clearUserData(state) {
            state.userinfo = null;
        }
    },
    actions: {
        logout({ commit }) {
            // 清除本地的 userinfo 状态
            commit('clearUserData');
        }
    },
    plugins:[
        createPersistedstate({
            key:'user',// 存数据的key名   自定义的  要有语义化
            // paths: ['loginModule'] // 要把那些模块加入缓存
        })
    ]

});
